import { axiosService } from '@/api/index';

function codeSend(postData) {
    return axiosService.post('member/email/code_send', postData);
}

function codeCheck(postData) {
    return axiosService.post('member/email/email_code_check', postData);
}

export {
    codeSend, codeCheck
};
