<template>
    <div class="container">
        <section class="section">
            <div class="text-start mb-5">
                <h1 class="fw-bold pb-3 border-bottom">로그인</h1>
            </div>
        </section>
        
        <div class="user_box bg-white shadow rounded-lg">
            <form action="" class="w-100 text-center">
                <div class="form-group moving_label bottom_line">
                    <input type="email" class="form-control" :class="{'is-invalid':validate.login_email===false}" v-model="email" @change="validateCheck"  id="user_em" autocomplete="off" placeholder=" " required>
                    <label for="user_em">이메일</label>
                    <div class="invalid-feedback text-start ps-2">{{validate_name.login_email}}</div>
                </div>
                <div class="form-group moving_label bottom_line my-5">
                    <input type="password" class="form-control" :class="{'is-invalid':validate.login_pw===false}" v-model="password" @change="validateCheck" id="user_pw" placeholder=" " required>
                    <label for="user_pw">비밀번호</label>
                    <div class="invalid-feedback text-start ps-2">{{validate_name.login_pw}}</div>
                </div>
                
                <div class="secu_code_box d-flex flex-column mb-4">
                    <div class="w-100 me-2 mb-3">
                        <div class="code border w-100">
                            {{secu_num}}
                        </div>
                    </div>
                    <div class="w-100">
                        <div class="form-group line">
                            <div class="d-flex justify-content-between mb-1">
                                <label>보안코드 입력</label>
                                <small class="countdown">{{ ShowTime(secu_count_num) }}</small>
                            </div>
                            <input type="number" class="form-control border" :class="{'is-invalid':validate.login_code===false}" v-model="code" @change="validateCheck" @keyup.enter="checkLogin()" placeholder="">
                            <div class="invalid-feedback text-start ps-2">{{validate_name.login_code}}</div>
                        </div>
                    </div>
                </div>
                
                <div class="d-flex mb-3 w-100 justify-content-between">
                    <router-link to="/signup" class="link-decoration d-block text-sm me-3 text-pale">회원가입</router-link>
                    <router-link to="/find-password" class="link-decoration d-block text-sm text-pale">비밀번호 찾기</router-link>
                </div>
                <b-button class="bg-primary border-0 btn-sm" pill @click="checkLogin">로그인</b-button>
            </form>
        </div>
    </div>
</template>

<script>
import {validateEmail, validatePW} from '@/utils/validation';
import {encoding, decoding} from '@/utils/security';
import {signIn} from '@/api/function/sign';

export default {
    data(){
        return{
            email: "",
            password: "",
            code: "",

            validate:{
                login_email:null,
                login_pw:null,
                login_code:null,
            },
            validate_name: {
                login_email: '',
                login_pw: '',
                login_code: '',
            },

            secu_num: 0,
            secu_count_int: 60,
            secu_count_num: 90,
        }
    },
    mounted(){
        this.SecuCountFn();
        this.secu_num = this.getRandomIntInclusive(10000,99999).toString();
    },
    methods:{  
        validateCheck(){
            if(this.email==''){
                this.validate_name.login_email = "필수 정보입니다."
                this.validate.login_email=false
            }else if(!validateEmail(this.email)){
                this.validate_name.login_email = "이메일을 올바르게 입력바랍니다."
                this.validate.login_email=false
            }else{
                this.validate.login_email=true
            }

            if(this.password==''){
                this.validate_name.login_pw = "필수 정보입니다."
                this.validate.login_pw = false
            }else{
                this.validate.login_pw=true
            }
            
            if(this.code==''){
                this.validate_name.login_code = "필수 정보입니다."
                this.validate.login_code=false
            }else if(this.code != this.secu_num){
                this.validate_name.login_code = "보안코드를 확인해주세요."
                this.validate.login_code=false
            }else{
                this.validate.login_code=true
            }
        },      
        checkLogin() {
            if(!this.validate.login_email) return this.validateCheck();
            else if(!this.validate.login_pw) return this.validateCheck();
            else if(!this.validate.login_code) return this.validateCheck();

            this.Login();
        },
        async Login() {
            const email = encoding(this.email);
            const password = encoding(this.password);
            
            const postData = {email, password};
            const {data} = await signIn(postData);
            if(data.code == '200'){
                const token = data.token;
                const email = data.email;
                const formData = {token: token, email: email};
                                                  
                this.$store.dispatch('SETLOGIN',{formData}).then(
                    ()=>{
                    this.$router.push({ name: 'Main' })
                });
            }
            else if(data.code == '100'){
                alert("정보를 확인해주세요.")
            }
            else if(data.code == '110'){
                alert("현재 접속불가상태입니다.")
            }
            else if(data.code == '111'){
                alert("현재 접속불가상태입니다.")
            }
            else if(data.code == '112'){                
                alert("현재 접속불가상태입니다.")
            }
            else {
                alert("정보를 확인해주세요.")
            }
        },
        getRandomIntInclusive :function(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min; //최댓값도 포함, 최솟값도 포함
        },
        SecuCountFn : function(){
            this.secu_count_int = setInterval(function(){
                if( this.secu_count_num > 0 ){
                    this.secu_count_num--;
                }else{
                    // 인터벌 종료
                    // clearInterval(this.secu_count_int)
                    this.secu_num = this.getRandomIntInclusive(10000,99999).toString();
                    this.secu_count_num = 90;
                }
            }.bind(this), 1000);
        },
        ShowTime : function(x){
            var min = Math.floor( x/60 );
            var sec = x%60;

            if (sec.toString().length==1) sec = "0" + sec;

            if(x >= 60){
                return min +":"+ sec
            }else{
                // this.ShowTime(this.secu_count_num);
                return "0:"+sec
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.secu_code_box .code{
    
    height: 66px;
    text-align: center;
    letter-spacing: 6px;
    font-size: 23px;
    font-weight: 700;
    background-color: rgb(249, 249, 249);
    line-height: 66px;
    font-style: italic;
    color: rgb(34, 34, 34);
    user-select: none;
}
</style>