<template>
    <div class="container">
        <section class="section">
            <div class="text-start mb-5">
                <h1 class="fw-bold pb-3 border-bottom">비밀번호 찾기</h1>
                <p class="text-pale py-2">가입한 이메일을 입력해주세요.</p>
            </div>
        </section>
        <div class="user_box bg-white shadow rounded-lg step01" v-if="step==1">
            <form action="" class="w-100 text-center">
                <div class="form-group moving_label bottom_line mb-5">
                    <input type="email" class="form-control" :class="{'is-invalid':validate.pass_email===false}" v-model="email" @change="validateCheck" id="user_em" autocomplete="off" placeholder=" " required>
                    <label for="user_em">이메일</label>
                    <div class="invalid-feedback text-start ps-2">{{validate_name.pass_email}}</div>
                </div>
                
                <b-button class="bg-primary border-0 btn-sm mb-4" pill @click="sendEmail()">인증번호 발송</b-button>
                <router-link to="/login" class="link-decoration d-block text-sm text-pale">로그인 페이지로 이동</router-link>
            </form>
        </div>
        <div class="user_box bg-white shadow rounded-lg step02" v-if="step == 2">
            <div class="top_txt_box mb-5 mt-4">
                <h3><div class="">인증번호 입력</div></h3>
                <p>
                    입력한 이메일로 인증번호를 전송하였습니다.<br>
                    인증번호를 입력해주세요.
                </p>
            </div>
            <div class="form_box">
                <div class="form-group moving_label bottom_line mb-5">
                    <input type="text" id="user_num" class="form-control" :class="{'is-invalid':validate.pass_code===false}" placeholder=" " v-model="code" @change="validateCheck" required>
                    <label for="user_num">인증번호</label>
                    <div class="invalid-feedback text-start ps-2">{{validate_name.pass_code}}</div>
                </div>
                
            </div>
            <div class="fixed_bottom_box p-3">
                <button type="button" class="btn bg-primary border-0 btn-sm btn-secondary rounded-pill" @click="confirmCode"><small>인증</small></button>
            </div>
        </div>
        <div class="user_box bg-white shadow rounded-lg step03" v-if="step == 3">
            <div class="top_txt_box mb-5 mt-4">
                <h3><div class="">새 비밀번호 전송</div></h3>
                <p>
                    인증된 이메일로 새 비밀번호를 전송하였습니다.<br>
                    새 비밀번호로 로그인 하신 후 비밀번호 변경을 진행해주세요.
                </p>
            </div>
            <div class="fixed_bottom_box p-3">
                <router-link to="/login" class="btn bg-primary border-0 btn-sm btn-secondary rounded-pill "><small>로그인</small></router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {validateEmail, validatePW} from '@/utils/validation';
import {encoding, decoding} from '@/utils/security';
import {codeSend, codeCheck} from '@/api/function/email';
import {passwordReset} from '@/api/function/sign';

export default {
    data(){
        return{
            email: "",
            code: '',
            
            validate:{
                pass_email:null,
                pass_code:null                
            },
            validate_name: {
                pass_email: '',
                pass_code: ''
            },

            step:1,
        }
    },
    methods:{
        validateCheck(){
            if(this.email==''){
                this.validate_name.pass_email = "필수 정보입니다."
                this.validate.pass_email=false
            }else if(!validateEmail(this.email)){
                this.validate_name.pass_email = "이메일을 올바르게 입력바랍니다."
                this.validate.pass_email=false
            }else{
                this.validate.pass_email=true
            }

            if(this.code==''){
                this.validate_name.pass_code = "필수 정보입니다."
                this.validate.pass_code=false
            }else{
                this.validate.pass_code=true
            }
        },      
        async sendEmail(){
            if(this.validate.pass_email){
                const email = encoding(this.email);
                const postData = {email};
                const {data} = await codeSend(postData);
                if(data.code == '100'){
                    alert("이메일을 확인해주세요.");
                }
                else {
                    alert("인증번호를 전송했습니다.");
                    this.step = 2;
                }
            }
            else {
                this.validateCheck();
            }
        },
        // 인증번호 확인
        async confirmCode() {
            if(this.validate.pass_code){
                const email = encoding(this.email);
                const code = encoding(this.code);

                const postData = {email, code};
                const {data} = await codeCheck(postData);
                
                if(data.code == '100'){
                    alert("인증번호를 확인해주세요.");
                }
                else {
                    this.passwordConfirm();
                }
            }
            else {
                this.validateCheck();
            }
        },
        async passwordConfirm(){
            this.validateCheck();
            
            const email = encoding(this.email);
            const postData = {email};
            const {data} = await passwordReset(postData);
            if(data.code == '100'){
                alert("비밀번호 변경 실패했습니다.")
            }
            else {
                this.step = 3;
            }
        }
    }
}
</script>

<style>
</style>