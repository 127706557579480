import axios from 'axios';
import { setInterceptors } from './common/interceptors';

function createAxiosService() {
	const axiosService = axios.create({
		baseURL: process.env.VUE_APP_API_URL,
	});
	return setInterceptors(axiosService);
}

function createAxiosServiceWithAuth(url) {
	const axiosService = axios.create({
		baseURL: `${process.env.VUE_APP_API_URL}/${url}`,
	});
	return setInterceptors(axiosService);
}

const axiosService = createAxiosService();
const posts = createAxiosServiceWithAuth('posts');

export { axiosService, posts };
