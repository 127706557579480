<template>
	<div></div>
</template>
<script>    
import {JoinEmailChk} from '@/api/function/check';
export default {
    data(){
        return{
        }
    },
    mounted(){
        this.Check();
    },
    methods : {
        async Check(){
            console.log("Dddddddddddd")
            const no =  this.$route.query.no;
            const code =  this.$route.query.code;
            const sec_no =  this.$route.query.sec_no;
            const postData = {no, code, sec_no};

            const {data} = await JoinEmailChk(postData);
            if(data.code == '200'){                
                alert('인증완료');
                this.$router.push({name: 'Login'});
            }
            else if(data.code == '110'){
                alert('이미 인증된 인증메일입니다.');
                this.$router.push({name: 'Login'});
            }
            else {
                alert('인증실패');
                this.$router.push({name: 'Login'});
            }
        }
    }
}
</script>
<style scoped>
	.main_balance_total span {display: inline-block;}
</style>