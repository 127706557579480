var CryptoJS = require("crypto-js");
const secret = process.env.VUE_APP_SECRET_TOKEN;

function encoding(text) {
    var ciphertext = CryptoJS.AES.encrypt(text, secret).toString();
    return ciphertext;
}

function decoding(text) {
    var bytes = CryptoJS.AES.decrypt(text, secret);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}

export {
    encoding, decoding
};
