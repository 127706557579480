<template>
    <div class="container">
        <section class="section">
            <div class="text-start mb-5">
                <h1 class="fw-bold pb-3 border-bottom">회원가입</h1>
            </div>
            
             <div class="user_box bg-white shadow rounded-lg">
                <form action="" class="w-100">
                    <div class="form-group moving_label bottom_line">
                        <input type="email" class="form-control" :class="{'is-invalid':validate.join_email===false}" v-model="join.email" @change="validateCheck" id="user_em" autocomplete="off" placeholder=" " required>
                        <label for="user_em">이메일</label>
                        <div class="invalid-feedback text-start ps-2">{{validate_name.join_email}}</div>
                    </div>
                    <div class="form-group moving_label bottom_line mt-5">
                        <input type="password" class="form-control" :class="{'is-invalid':validate.join_pw===false}" v-model="join.pw" @change="validateCheck" id="user_pw" placeholder=" " required>
                        <label for="user_pw">비밀번호 (8~16자리의 영문, 숫자, 특수기호)</label>
                        <div class="invalid-feedback text-start ps-2">{{validate_name.join_pw}}</div>
                    </div>
                    <div class="form-group moving_label bottom_line mt-5">
                        <input type="password" class="form-control" :class="{'is-invalid':validate.join_pw_c===false}" v-model="join.pw_c" @change="validateCheck" id="user_pw2" placeholder=" " required>
                        <label for="user_pw2">비밀번호 확인</label>
                        <div class="invalid-feedback text-start ps-2">{{validate_name.join_pw_c}}</div>
                    </div>
                    <div class="form-group moving_label bottom_line mt-5">
                        <input type="password" class="form-control" :class="{'is-invalid':validate.join_pin===false}" v-model="number" @change="validateCheck" id="user_pin" pattern="[0-9]*" maxlength="8" placeholder=" " required>
                        <label for="user_pin">거래 비밀번호 (8자리의 숫자)</label>
                        <div class="invalid-feedback text-start ps-2">{{validate_name.join_pin}}</div>       
                    </div>

                    <div class="custom_checkbox my-5">
                        <div class="checkbox d-flex justify-content-between mb-2">
                            <div class="checkbox circle">
                                <label>
                                    <input type="checkbox" v-model="allTermChk" hidden>
                                    <div class="check_icon"></div>
                                    <strong> 서비스 관련 약관 전체 동의</strong>
                                </label>
                            </div>
                        </div>
                        <div class="checkbox d-flex flex-column text-start mb-3" v-for="(item, index) in terms" :key="index">
                            <label>
                                <input type="checkbox" v-model="termsChk" :value="index" hidden>
                                <div class="check_icon"></div>
                                {{item.title}}
                            </label>
                            <div class="p-3 mt-3">
                                <p v-html="item.content" class="text-sm"></p>
                            </div>
                            <!-- <span class="main_color cursor_pointer small" v-b-modal.terms_modal @click="chg_terms(item)">내용보기</span> -->
                        </div>
                    </div>
                   <b-button class="bg-primary border-0 btn-sm mb-3" pill @click="checkSign()">회원가입</b-button>
                    <router-link to="/login" class="link-decoration d-block text-sm text-pale">이미 아이디가 있어요</router-link>
                </form>
                
                <!-- <b-modal id="terms_modal" :title="terms_title" scrollable ok-only modal-class="pp-box" header-class="pp-header">
                    <pre class="my-4 col-lg-12">{{terms_desc}}</pre>
                </b-modal> -->
            </div>
        </section>
    </div>
</template>

<script>
import {validateEmail, validatePW, numberMask} from '@/utils/validation';
import {encoding} from '@/utils/security';
import {signUp} from '@/api/function/sign';
import {term} from '@/api/function/list';

export default {
    data(){
        return{       
            join: {
                email: '',
                pw: '',
                pw_c: '',
                pin: ''
            },
            number: '',
            validate:{
                join_email:null,
                join_pw:null,
                join_pw_c:null,
                join_pin:null,
            },
            validate_name: {
                join_email: '',
                join_pw: '',
                join_pw_c: '',
                join_pin: ''
            },
            terms_title: "",
            terms_desc: "",
            terms: [],
            termsChk: [],
        }
    },
    watch: {
        number: function() {
            const num = numberMask(this.number);
            this.join.pin = num;
            return this.number = num;
        }
    },
    mounted() {
        this.termList();
    },
    computed: {
        allTermChk: {
            get: function(){
                let _length = 0;
                if( this.terms.length != 0 ){
                    _length = this.terms.length;
                }
                if( this.termsChk.length != _length ){
                    return false
                }else{
                    return true
                }
            },
            set: function(e){
                if( e ){
                    let _length = 0;
                    if( this.terms.length != 0 ){
                        _length = this.terms.length;
                    }

                    let _list = [];
                    for( var i=0; i<_length; i++ ){
                        _list.push(i);
                    }
                    this.termsChk = _list;
                }else{
                    this.termsChk = [];
                }
            }
        },
    },
    methods:{        
        validateCheck(){
            if(this.join.email==''){
                this.validate_name.join_email = "필수 정보입니다."
                this.validate.join_email=false
            }else if(!validateEmail(this.join.email)){
                this.validate_name.join_email = "이메일을 올바르게 입력바랍니다."
                this.validate.join_email=false
            }else{
                this.validate.join_email=true
            }

            if(this.join.pw==''){
                this.validate_name.join_pw = "필수 정보입니다."
                this.validate.join_pw = false
            }else if(!validatePW(this.join.pw)){
                this.validate_name.join_pw = "비밀번호를 올바르게 입력바랍니다."
                this.validate.join_pw=false
            }else{
                this.validate.join_pw=true
            }
            
            if(this.join.pw_c==''){
                this.validate_name.join_pw_c = "필수 정보입니다."
                this.validate.join_pw_c=false
            }else if(this.join.pw_c!=this.join.pw){
                this.validate_name.join_pw_c = "입력하신 비밀번호와 다릅니다."
                this.validate.join_pw_c=false
            }else{
                this.validate.join_pw_c=true
            }

            if(this.join.pin==''){
                this.validate_name.join_pin = "필수 정보입니다."
                this.validate.join_pin=false
            }else if(this.join.pin.length != 8){
                this.validate_name.join_pin = "출금비밀번호 8자리를 입력바랍니다."
                this.validate.join_pin=false
            }else{
                this.validate.join_pin=true
            }
        },
        checkSign() {
            if(!this.validate.join_email) return this.validateCheck();
            else if(!this.validate.join_pw) return this.validateCheck();
            else if(!this.validate.join_pw_c) return this.validateCheck();
            else if(!this.validate.join_pin) return this.validateCheck();

            if(this.terms.length != this.termsChk.length) return alert("약관에 동의해주세요.");

            this.sign();
        },
        async sign() {
            const email = encoding(this.join.email);
            const password = encoding(this.join.pw);
            const pin = encoding(this.join.pin);
            const lang = 'ko';

            const postData = {
                email, password, pin, lang
            }
            const {data} = await signUp(postData);
            if(data.code == '100'){
                alert("이미 가입된 이메일입니다.");
            }
            else if(data.code == '200'){
                this.$router.push({name : 'Login'});
            }
            else {
                alert('가입도중 오류가 발생했습니다.');
            }
        },
        async termList() {
            const {data} = await term();
            if(data.code == '200') this.terms = data.list;
        },
        chg_terms(a){
            this.terms_title = a.title;
            this.terms_desc = a.content;
        }
    
    }
}
</script>

<style lang="scss" scoped>
.user_box{
    padding: 62px 24px 32px;
    width: 470px;
    margin: 0 auto;
}
.custom_checkbox .checkbox label+div{
    background-color: #eee;
    border-radius: 8px;
    >p{
        height: 70px;
        overflow-y: auto;
    }
}
</style>