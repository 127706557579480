function validateEmail(email) {
	// const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const re = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
	return re.test(String(email).toLowerCase());
}

function validatePW(password) {
	const re = /^(?=.*[A-Za-z0-9])(?=.*\d)(?=.*[$@$!%*#?&+])[A-Za-z\d$@$!%*#?&+]{8,16}$/;
	return re.test(String(password).toLowerCase());
}

function validatePhone(phone) {
	var re = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
	return re.test(String(phone).toLowerCase());
}

function numberMask(num) {
	var re = /[^0-9]/g;
	return num.replace(re, '');
}

export {
	validateEmail,
	validatePW,
	validatePhone,
	numberMask
};
