<template>
    <div class="">
        <section class="bg-pastel p-5 text-start position-relative overflow-hidden">
            <div class="d-flex container py-5">
                <div class="deco_img scale-3">
                    <img src="@/assets/img/logo_color.svg" alt="logo" class="w-100">
                </div>
                <div class="col-md-7 col-12">
                    <p class="tit mb-4">NFT 마켓 방문을 환영합니다</p>
                    <p class="mb-3 pb-5">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, nam facere autem eius esse aperiam accusantium alias error unde ipsum voluptates sequi dolorum maxime, dolore rerum officiis. Quia rerum quas quisquam asperiores at reiciendis error, similique neque
                    </p>
                    <div>
                        <div class="btn btn-main me-2 fw-bold">SWAP</div>
                        <div class="btn btn-outline-main fw-bold">BUY NFT</div>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-white p-5">
            <div class="d-flex container py-4 justify-content-center">
                <div class="w-100">
                    <span class="text-main fw-bold">sub title</span>
                    <p class="tit mb-5 pb-3">TITLE 1</p>
                    <div class="d-flex gap-4 mt-5 pt-5 card_box">
                        <div class="card shadow-sm col-12 col-md-4 p-3 p-md-5">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima hic consequuntur maxime recusandae veniam illo quaerat odio eveniet nobis, perspiciatis debitis facere minus amet non ipsum ab laborum ipsa eaque. Unde dolores porro aperiam inventore est dolorum impedit eum tempora.</p>
                        </div>
                        <div class="card shadow-sm col-12 col-md-4 p-3 p-md-5">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima hic consequuntur maxime recusandae veniam illo quaerat odio eveniet nobis, perspiciatis debitis facere minus amet non ipsum ab laborum ipsa eaque. Unde dolores porro aperiam inventore est dolorum impedit eum tempora.</p>
                        </div>
                        <div class="card shadow-sm col-12 col-md-4 p-3 p-md-5">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima hic consequuntur maxime recusandae veniam illo quaerat odio eveniet nobis, perspiciatis debitis facere minus amet non ipsum ab laborum ipsa eaque. Unde dolores porro aperiam inventore est dolorum impedit eum tempora.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-light p-5">
            <div class="d-flex container py-4 justify-content-center">
                <div class="w-100">
                    <span class="text-main fw-bold">sub title</span>
                    <p class="tit mb-4">TITLE 1</p>
                    <p class="mb-5 pb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut excepturi exercitationem corrupti dolorum animi assumenda est fugiat tenetur sed impedit quia dolor optio at accusantium, in rem laborum ex voluptas?</p>
                    <div class="d-flex mt-5 pt-5 flex-wrap icon_box">
                        <div class="position-relative item p-3">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="fw-bold pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum rem non qui</p>
                        </div>
                        <div class="position-relative item p-3">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="fw-bold pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum rem non qui</p>
                        </div>
                        <div class="position-relative item p-3">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="fw-bold pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum rem non qui</p>
                        </div>
                        <div class="position-relative item p-3">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="fw-bold pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum rem non qui</p>
                        </div>
                        <div class="position-relative item p-3">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="fw-bold pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum rem non qui</p>
                        </div>
                        <div class="position-relative item p-3">
                            <div class="icon">
                                <i class="fas fa-gamepad"></i>
                            </div>
                            <h3 class="fw-bold pt-5 mt-3">Title</h3>
                            <p class="pt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum rem non qui</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-white p-5">
            <div class="d-flex container py-4 justify-content-center">
                <div class="w-100">
                    <span class="text-main fw-bold">sub title</span>
                    <p class="tit mb-4">TITLE 1</p>
                    <div class="text-start">
                        <ul class="col-12 col-md-10 mx-auto">
                            <li class="mb-5">
                                <h3 class="text-main">
                                    <span class="step">1</span>
                                    지갑 생성</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione mollitia voluptates natus est, eos repellendus animi voluptas odio dicta magnam. Blanditiis ipsa libero, labore possimus reiciendis perferendis fuga est odio soluta iure expedita, amet aspernatur?</p>
                            </li>
                            <li class="mb-5">
                                <h3 class="text-main">
                                    <span class="step">2</span>
                                    Binance Chain Wallet에 $ TOKEN 보내기</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam quia libero non saepe ipsa molestias aliquid quis excepturi fugit alias?</p>
                            </li>
                            <li class="mb-5">
                                <h3 class="text-main">
                                    <span class="step">3</span>
                                    교환</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum accusamus temporibus ex quas cum optio quidem, animi delectus hic mollitia nam voluptatibus voluptatum aspernatur aperiam minus. Suscipit quibusdam cumque animi velit facilis, dignissimos nobis! Itaque consectetur est suscipit, perferendis repellendus voluptate distinctio enim vitae veniam voluptatem corrupti magnam, architecto aliquid.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-light p-5">
            <div class="container">
                <div class="w-100">
                    <span class="text-main fw-bold">FAQ</span>
                    <p class="tit mb-5 pb-3">자주 묻는 질문</p>
                    <div>
                        <details class="text-start mb-4 border-bottom pb-2" v-for="(item,index) in faqList" :key="index">
                            <summary class="fw-bold mb-4 fs-3">Q. {{item.question}}</summary>
                            <div class="p-3 rounded-lg bg-white fs-5">{{item.answer}}</div>
                        </details>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-white p-5">
            <div class="container">
                <div class="w-100">
                    <p class="tit mb-5 pb-3">RoadMap</p>
                    <div class="row d-flex flex-wrap">
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item roadmap-done">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item roadmap-done">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item roadmap-done">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item roadmap-done">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item roadmap-done">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item roadmap-done">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item roadmap-done">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item roadmap-done">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="roadmap-item">
                                <i class="fas fa-check-circle fa-2x pe-3"></i>
                                RoadMap Title
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data(){
        return{
            faqList:[
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>

    section.p-5{
        padding: 0;
    }
    .wrap{
        position: absolute;
        width: 100%;
        top: 68px;
        left: 0px;
    }

    .tit{
        font-size: 50px;
        font-weight: bold;
    }

    .bg-main-gradient{
        background: linear-gradient();
    }

    .deco_img{
        position: absolute;
        opacity: 0.2;
        right: 20%;
        transform: scale(3);
    }

    .icon{
        width: 120px;
        height: 120px;
        background-color: #f3f3f3;
        text-align: center;
        border-radius: 100%;
        overflow: hidden;
        box-shadow: 0 3px 6px rgba(0,0,0,0.2);
        font-size: 35px;
        color: #fff;
        margin: 0 auto 45px;
        border: 10px solid #fff;
        color: #D3308D;
        position: absolute;
        top: -60px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon_box{
        .item{
            width: 33.333%;
            margin-bottom: 5.2em;
        }
    }

    .step{
        display: inline-block;
        border: 1px solid #D3308D;
        color: #D3308D;
        border-radius: 100%;
        width: 36px;
        height: 36px;
        line-height: 27px;
        text-align: center;
    }
    
    .custom-table{
        .thead .tr{
            background-color: #eee;
        }
        .tr{
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
        }
        .td, .th{
            padding: 15px;
        }
    }
    
    .card_box{
        // flex-wrap: wrap;
        justify-content: center;

        .card{
            width: 33.333%;
            margin-right: 1%;

            &:nth-child(3n){
                margin-right: 0;
            }
        }
    }

    .roadmap-item{
        height: 80px;
        background: #fff;
        box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        font-weight: 400;
        color: #2f2d41;
        line-height: 24px;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 25px;
        padding-right: 25px;
        margin-bottom: 30px;
        cursor: default;
        transition: transform .2s ease;

        i{
            color: #ccc;
        }

        &.roadmap-done{
            background-color: #D3308D;
            color: #fff;

            i{
                color: #fff;
            }
        }

        &:hover{
            transform: translateY(-4px);
        }
    }
@media screen and (max-width:767px) {
    section:first-child{
        margin-top: 68px;
    }
    .tit{
        font-size: 36px;
    }

    .card_box{
        flex-wrap: wrap;

        .card{
            width: 100%;
            margin-bottom: 50px;
        }
    }

    .icon_box{
        .item{
            width: 100%;
            margin-bottom: 5.2em;
        }
    }

}
</style>