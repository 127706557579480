import { axiosService } from '@/api/index';

function signUp(postData) {
    return axiosService.post('member/sign/up', postData);
}

function signIn(postData) {
    return axiosService.post('member/sign/in', postData);
}

function passwordReset(postData) {
    return axiosService.post('member/sign/password_find', postData);
}

export {
    signUp, signIn, passwordReset
};
